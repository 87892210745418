import { template as template_f43f476b5490432aaa80cc85d7c16eac } from "@ember/template-compiler";
const WelcomeHeader = template_f43f476b5490432aaa80cc85d7c16eac(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
