import { template as template_d56f088d27834e889e185f67547ca1db } from "@ember/template-compiler";
const FKLabel = template_d56f088d27834e889e185f67547ca1db(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
